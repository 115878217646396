import React, { useState } from "react";
import styled, { ThemeProvider } from "styled-components";

import { QuestionType } from "types/questionTypes";
import { white } from "./theme";
import { VoteStatus } from "types/voteEnum";

import ScreenRemoteVotebox from "./ScreenRemote/ScreenRemoteVotebox";
import KeyboardVotebox from "./Keyboard/KeyboardVotebox";

type Props = {
    onValidate: (result: string) => any;
    isSecret: boolean;
    answerCanBeModified: boolean;
    remoteMaxNumberButton: number;
};

const VirtualRemoteVoteboxDeliberation = ({
    onValidate,
    isSecret,
    answerCanBeModified,
    remoteMaxNumberButton,
}: Props) => {
    const [answer, setAnswer] = useState("");
    const [voteStatus, setVoteStatus] = useState<string>(VoteStatus.Idle);

    const handleValidate = () => {
        if (0 === answer.length) {
            return;
        }

        onValidate(answer)
            .then(() => {
                setVoteStatus(VoteStatus.Success);
            })
            .catch(() => {
                setVoteStatus(VoteStatus.Error);
            });

        if (isSecret) {
            setAnswer("");
        }
    };

    const onReset = () => {
        if (!answerCanBeModified) {
            return;
        }
        setAnswer("");
        setVoteStatus(VoteStatus.Idle);
    };

    const onButtonClick = (value: React.SetStateAction<string>) => {
        if (voteStatus === VoteStatus.Success) {
            setVoteStatus(VoteStatus.Idle);
            setAnswer(value);
        } else {
            setAnswer(value);
        }
    };

    return (
        <ThemeProvider theme={white}>
            <Wrapper>
                <ScreenRemoteVotebox answer={answer} voteStatus={voteStatus} />
                <KeyboardVotebox
                    handleValidate={handleValidate}
                    onReset={onReset}
                    onButtonClick={onButtonClick}
                    remoteMaxNumberButton={remoteMaxNumberButton}
                    typeVote={QuestionType.DELIBERATION}
                />
            </Wrapper>
        </ThemeProvider>
    );
};

const Wrapper = styled.div`
    border-radius: 16px;
    padding: 8px 8px 16px;
    width: 208px;
    border: ${(props) => props.theme.border} 1px solid;
    background: ${(props) => props.theme.backgroundColor};
    margin: auto;
`;

export default VirtualRemoteVoteboxDeliberation;
