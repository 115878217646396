import styled from "styled-components";
import { VoteStatus } from "types/voteEnum";

type Props = {
    voteStatus: string;
};

const MessageVoteStatus = ({ voteStatus }: Props) => {
    const renderMessage = () => {
        if (voteStatus === VoteStatus.Success) {
            return <StyledMessage>OK</StyledMessage>;
        } else if (voteStatus === VoteStatus.Error) {
            return <StyledMessage>ERROR</StyledMessage>;
        } else {
            return null;
        }
    };

    return <Wrapper>{renderMessage()}</Wrapper>;
};

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 3px 10px 0px 5px;
    height: 10px;
`;

const StyledMessage = styled.div`
    font-size: 13px;
    padding-right: 5px;
    letter-spacing: 0.15rem;
`;

export default MessageVoteStatus;
