import styled from "styled-components";
import MessageVoteStatus from "./MessageVoteStatus";

type Props = {
    answer: string;
    voteStatus: string;
};

interface StyledButtonProps {
    fontSize: string;
}

const ScreenRemoteQuizzbox = ({ answer, voteStatus }: Props) => {
    return (
        <Wrapper>
            <Screen data-test-id="screen-result">
                <MessageVoteStatus voteStatus={voteStatus} />
                <AnswerWrapper fontSize={answer.length >= 8 ? "22px" : "36px"}>
                    {answer}
                </AnswerWrapper>
            </Screen>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    padding: 20px 16px 8px;
`;

const Screen = styled.div`
    height: ${(props) => props.theme.screen.height};
    padding-left: 4px;
    background: ${(props) => props.theme.screen.backgroundColor};
    text-align: right;
    font-weight: 700;
    margin: 0 0 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    position: relative;
`;

const AnswerWrapper = styled.div<StyledButtonProps>`
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #555;
    padding-right: 16px;
    font-size: ${(props) => props.fontSize};
`;

export default ScreenRemoteQuizzbox;
