import {
    Dispatch,
    SetStateAction,
    SyntheticEvent,
    useEffect,
    useState,
} from "react";
import styled from "styled-components";
import { IconContainerProps } from "@mui/material/Rating";

import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import { useTranslation } from "react-i18next";

import { VoteStatus } from "types/voteEnum";

import ActionSection from "../../../common/ActionSection";
import TitleHeader from "../../../common/TitleHeader";
import InputEvaluation from "./InputEvaluation";
import { EvaluationDisplayType, EvaluationQuestion } from "types/dataVoteType";
import emitAsync from "components/helper/emitAsync";
import { useUser } from "components/helper/userContext";
import HeartsEvaluation from "./HeartsEvaluation";
import SmilesEvaluation from "./SmilesEvaluation";
import StarsEvaluation from "./StarsEvaluation";
import { User } from "types/User";

export type Answer = {
    questionId: string;
    answer: number | null;
    weight: number;
};

type Props = {
    evaluationQuestions: Array<EvaluationQuestion>;
    title: string;
    useDecimal: boolean;
    minValue: number;
    maxValue: number;
    evaluationDisplayType: EvaluationDisplayType;
    responseFormatVersion: number;
};

type InheritedPops = {
    id: number;
    index: number;
    subTitle: string;
    answers: Answer[];
    onRateChange: (
        event: SyntheticEvent<Element, Event>,
        newValue: number | null,
        id: number,
        index: number
    ) => void;
    setAnswers: Dispatch<SetStateAction<Answer[]>>;
    isResetInputs: boolean;
    useDecimal: boolean;
    minValue: number;
    maxValue: number;
    evaluationQuestions: Array<EvaluationQuestion>;
};

export type EvaluationProps = Omit<
    InheritedPops,
    "setAnswers" | "isResetInputs" | "subTitles" | "minValue"
>;

type NumericEvaluationProps = Omit<InheritedPops, "onRateChange">;

const customIcons: {
    [index: string]: {
        icon: React.ReactElement;
        label: string;
    };
} = {
    1: {
        icon: <SentimentVeryDissatisfiedIcon color="error" />,
        label: "Very Dissatisfied",
    },
    2: {
        icon: <SentimentDissatisfiedIcon color="error" />,
        label: "Dissatisfied",
    },
    3: {
        icon: <SentimentSatisfiedIcon color="warning" />,
        label: "Neutral",
    },
    4: {
        icon: <SentimentSatisfiedAltIcon color="success" />,
        label: "Satisfied",
    },
    5: {
        icon: <SentimentVerySatisfiedIcon color="success" />,
        label: "Very Satisfied",
    },
};

export function IconContainer(props: IconContainerProps) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
}

const NumericEvaluation = (props: NumericEvaluationProps) => {
    return (
        <InputEvaluation
            id={props.id}
            useDecimal={props.useDecimal}
            minValue={props.minValue}
            maxValue={props.maxValue}
            answers={props.answers}
            setAnswers={props.setAnswers}
            index={props.id - 1}
            reset={props.isResetInputs}
            subTitle={props.evaluationQuestions[props.id - 1].title}
        />
    );
};

export const getComponentFromType = (
    evaluationDisplayType: EvaluationDisplayType,
    props: InheritedPops
) => {
    switch (evaluationDisplayType) {
        case EvaluationDisplayType.NUMBER:
            return NumericEvaluation(props);
        case EvaluationDisplayType.HEARTS:
            return HeartsEvaluation(props);
        case EvaluationDisplayType.SMILES:
            return SmilesEvaluation(props);
        case EvaluationDisplayType.STARS:
            return StarsEvaluation(props);
    }
};

const GroupedEvaluation = (props: Props) => {
    const {
        title,
        evaluationQuestions,
        evaluationDisplayType,
        responseFormatVersion,
    }: Props = props;

    const user = useUser().user as User;
    const i18n = useTranslation();

    const [answers, setAnswers] = useState<Array<Answer>>([]);
    const [voteStatus, setVoteStatus] = useState(VoteStatus.Idle);
    const [isResetInputs, setIsResetInputs] = useState(false);

    // Once the number of answers is reached, the vote is ready to be sent
    useEffect(() => {
        if (
            answers &&
            answers.length === evaluationQuestions.length &&
            answers.every((answer) => answer && !Number.isNaN(answer.answer))
        ) {
            setVoteStatus(VoteStatus.PendingValidation);
        }
    }, [answers, evaluationQuestions.length]);

    const handleValidate = () => {
        return emitAsync({
            responseFormatVersion,
            votingDeviceId: user.numTelecoEncrypted,
            answers: evaluationQuestions.map((question) => ({
                questionId: question.id,
                answer: (answers[question.id - 1].answer as number).toString(),
                weight: 1,
            })),
        })
            .then(() => {
                setVoteStatus(VoteStatus.Success);
                setIsResetInputs(false);
            })
            .catch(() => {
                setVoteStatus(VoteStatus.Error);
            });
    };

    const handleReset = () => {
        setVoteStatus(VoteStatus.Idle);
        setAnswers([]);
        setIsResetInputs(true);
    };

    const onRateChange = (
        event: SyntheticEvent<Element, Event>,
        newValue: number | null,
        id: number,
        index: number
    ) => {
        const newAnswers = [...answers];

        newAnswers[index] = {
            answer: newValue,
            weight: 1,
            questionId: id.toString(),
        };
        setAnswers(newAnswers);
    };

    const renderQuestions = () => {
        return evaluationQuestions.map((question) => (
            <div key={question.id}>
                {getComponentFromType(evaluationDisplayType, {
                    ...props,
                    setAnswers,
                    answers,
                    isResetInputs,
                    id: question.id,
                    index: question.id - 1,
                    subTitle: question.title,
                    onRateChange,
                })}
            </div>
        ));
    };

    return (
        <WrapperVote>
            <TitleHeader title={title} />
            {props.maxValue === 0 ? (
                <p>
                    {i18n.t("noteVotingRange", {
                        min: props.minValue,
                        max: props.maxValue,
                    })}
                </p>
            ) : null}
            {renderQuestions()}
            <ActionSection
                voteStatus={voteStatus}
                handleValidate={handleValidate}
                handleReset={handleReset}
            />
        </WrapperVote>
    );
};

const WrapperVote = styled.div`
    @media (max-width: 768px) {
        max-width: 350px;
    }
    > span {
        font-size: 1.3rem;
    }
`;

export const RatingWrapper = styled.div`
    display: flex;
    margin-bottom: 1rem;
    margin-right: 1rem;
    > p {
        margin: 0 1rem;
        width: 100%;
    }
`;

export default GroupedEvaluation;
