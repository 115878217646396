import { useEffect, useState, FormEvent, ChangeEvent } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import { VoteStatus } from "types/voteEnum";
import { sendTagCloudToDisplay } from "components/helper/callApi";
import { useUser } from "components/helper/userContext";
import { useParams } from "react-router-dom";
import { HTTPResponseError } from "services/errors";

import ActionSection from "../../common/ActionSection";
import Spinner from "../../../../common/Spinner";
import TitleHeader from "../../common/TitleHeader";

type Props = {
    onValidate: any;
    title: string;
    numberOfInputs: number;
    userCanSubmitMultipleTimes: boolean;
};

const TagCloudQuestion = ({
    title,
    onValidate,
    numberOfInputs,
    userCanSubmitMultipleTimes,
}: Props) => {
    //Transform the numberOfInputs to array
    const initialEmptyArray = Array(numberOfInputs).fill("");

    const { onlineCode } = useParams();
    const { user } = useUser();
    const { enqueueSnackbar } = useSnackbar();
    const i18n = useTranslation();

    const [answers, setAnswers] = useState<Array<string>>(initialEmptyArray);
    const [voteStatus, setVoteStatus] = useState<string>(VoteStatus.Idle);
    const [answersHaveAlreadyBeenSent, setAnswersHaveAlreadyBeenSent] =
        useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);

    const answersIsEmpty = answers.every((answer) => 0 === answer.length);

    useEffect(() => {
        if (answersIsEmpty) {
            setVoteStatus(VoteStatus.Idle);
        } else {
            setVoteStatus(VoteStatus.PendingValidation);
        }
    }, [answersIsEmpty]);

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        handleValidate();
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
        const newAnswers = [...answers];
        newAnswers[index] = e.target.value;

        setVoteStatus(VoteStatus.PendingValidation);
        setAnswers(newAnswers);
    };

    const sendToTagsCloud = async (answers: Array<string>) => {
        const answersFiltered = answers.filter((answer) => answer !== "");

        const response = {
            words: answersFiltered,
            remoteNumber: user?.numTelecoEncrypted,
        };

        return sendTagCloudToDisplay(onlineCode as string, response)
            .then((res) => {
                if (!res.ok) {
                    throw new HTTPResponseError(res);
                }
            })
            .catch((error) => {
                if (!(error instanceof HTTPResponseError)) {
                    enqueueSnackbar(`${i18n.t("errorUnknown")}`);
                }

                const res = error.response;

                if (res.status === 400) {
                    enqueueSnackbar(`${i18n.t("errorFieldsEmpty")}`, {
                        variant: "error",
                    });
                } else {
                    enqueueSnackbar(`${i18n.t("errorUnknown")}`, {
                        variant: "error",
                    });
                }

                throw error;
            });
    };

    const handleValidate = () => {
        setIsLoading(true);

        sendToTagsCloud(answers)
            .then(() => {
                onValidate(answers)
                    .then(() => {
                        setAnswersHaveAlreadyBeenSent(true);
                        setIsLoading(false);
                    })
                    .catch(() => {
                        setIsLoading(false);
                        setVoteStatus(VoteStatus.Error);
                    });
            })
            .catch(() => {
                setIsLoading(false);
            });

        setAnswers(initialEmptyArray);
    };

    const renderTagCloud = () => {
        return (
            <>
                {answersHaveAlreadyBeenSent ? (
                    <StyledParagraph>
                        {i18n.t("responseHasBeenConsidered")},
                        {i18n.t("youCanResendResponse")}
                    </StyledParagraph>
                ) : null}
                <WrapperForm>
                    <form onSubmit={handleSubmit} autoComplete="off">
                        <div>
                            {initialEmptyArray.map((input, index) => {
                                return (
                                    <StyledInput
                                        value={answers[index] || ""}
                                        onChange={(e) => handleChange(e, index)}
                                        type={"text"}
                                        maxLength={30}
                                        placeholder={
                                            i18n.t("enterWord") as string
                                        }
                                        aria-label={`tag-cloud-${index}`}
                                        key={index}
                                    />
                                );
                            })}
                        </div>
                    </form>
                </WrapperForm>
                <ActionSection
                    voteStatus={voteStatus}
                    handleValidate={handleValidate}
                />
            </>
        );
    };

    return isLoading ? (
        <SpinnerWrapper>
            <Spinner />
        </SpinnerWrapper>
    ) : (
        <WrapperVote>
            <TitleHeader title={title} />
            {!userCanSubmitMultipleTimes && answersHaveAlreadyBeenSent ? (
                <StyledParagraph>
                    {`${i18n.t("responseHasBeenConsidered")}`}
                </StyledParagraph>
            ) : (
                renderTagCloud()
            )}
        </WrapperVote>
    );
};

const WrapperVote = styled.div`
    width: 100%;
    max-width: 620px;
    margin: auto;
    @media (max-width: 768px) {
        max-width: 350px;
    }
`;

const WrapperForm = styled.div`
    text-align: center;
    margin-top: 1.5rem;
`;

const StyledInput = styled.input`
    margin: 0.75rem auto;
    width: 90%;
    height: 25px;
    padding: 6px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 15px;
    &[disabled] {
        background: #e8e8e8;
        opacity: 0.7;
        cursor: not-allowed;
    }
    :focus-visible {
        outline: solid 1px #010203;
    }
`;

const StyledParagraph = styled.p`
    margin-top: 2.5rem;
`;

const SpinnerWrapper = styled.div`
    padding-bottom: 20px;
`;

export default TagCloudQuestion;
