import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { VoteStatus } from "types/voteEnum";

import OptionsWrapper from "./OptionsWrapper";
import ActionSection from "../../../common/ActionSection";
import TitleHeader from "../../../common/TitleHeader";

type Props = {
    onValidate: (answer: string | Array<string>) => Promise<{
        [key: string]: any;
    }>;
    subTitles: Array<string>;
    options: Array<{
        id: string;
        text: string;
    }>;
    title: string;
};

const GroupedQuestion = ({ onValidate, options, title, subTitles }: Props) => {
    const [answers, setAnswers] = useState<Array<string | undefined>>([]);
    const [voteStatus, setVoteStatus] = useState(VoteStatus.Idle);

    // Once the number of answers is reached, the vote is ready to be sent
    useEffect(() => {
        const definedAnswers = answers.filter((el) => el !== undefined);

        if (definedAnswers.length === subTitles.length) {
            setVoteStatus(VoteStatus.PendingValidation);
        }
    }, [answers, subTitles.length]);

    const handleValidate = () => {
        setVoteStatus(VoteStatus.Loading);

        onValidate(answers.join(""))
            .then(() => {
                setVoteStatus(VoteStatus.Success);
            })
            .catch(() => {
                setVoteStatus(VoteStatus.Error);
            });
    };

    const handleReset = () => {
        setVoteStatus(VoteStatus.Idle);
    };

    const renderQuestions = () => {
        const QuestionElements: Array<React.ReactElement> = [];

        for (let i = 1; i <= subTitles.length; i++) {
            QuestionElements.push(
                <div key={i}>
                    <TitleHeader title={subTitles[i - 1]} />
                    <OptionsWrapper
                        index={i}
                        options={options}
                        answers={answers}
                        setAnswers={setAnswers}
                        setVoteStatus={setVoteStatus}
                    />
                </div>
            );
        }

        return QuestionElements;
    };

    return (
        <WrapperVote>
            <TitleHeader title={title} />
            {renderQuestions()}
            <ActionSection
                voteStatus={voteStatus}
                handleValidate={handleValidate}
                handleReset={handleReset}
            />
        </WrapperVote>
    );
};

const WrapperVote = styled.div`
    @media (max-width: 768px) {
        max-width: 350px;
    }
    > span {
        font-size: 1.3rem;
    }
`;

export default GroupedQuestion;
