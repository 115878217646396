import { useEffect, useRef, useState } from "react";
import SignaturePad from "signature_pad";
import styled from "styled-components";

import { CSS_VARIABLES } from "enums/cssVariables";
import { useTranslation } from "react-i18next";
import { performSignature } from "components/helper/callApi";
import { useUser } from "components/helper/userContext";
import { useHandleErrors } from "./common/handleErrors";
import ErrorPage from "../common/ErrorPage";
import { DisplayedError, NotFoundError, UnknownError } from "services/errors";
import Layout from "../common/Layout";
import LogoutWrapper from "components/common/LogoutWrapper";
import SignatureLogoutButton from "./common/SignatureLogoutButton";
import ProxiesAccordion from "../vote/Votebox/common/AutomaticVoteForProxiesComponents/ProxiesAccordion";

const Signature = () => {
    const canvasSignatureRef = useRef<HTMLCanvasElement>(null);
    const underLayerSignatureCanvasRef = useRef<HTMLCanvasElement>(null);

    const signaturePadRef = useRef<SignaturePad>();
    const [isSignaturePadEmpty, setIsSignaturePadEmpty] = useState(true);

    const { user, setUser } = useUser();
    const [error, setError] = useState<DisplayedError | null>(null);

    const { handleLoginError } = useHandleErrors();

    const i18n = useTranslation();

    useEffect(() => {
        const signatureCanvas = canvasSignatureRef.current;
        const underLayerCanvas = underLayerSignatureCanvasRef.current;

        if (!signatureCanvas || !underLayerCanvas) {
            return;
        }

        signatureCanvas.width = 470;
        signatureCanvas.height = 300;
        underLayerCanvas.width = 470;
        underLayerCanvas.height = 300;

        signaturePadRef.current = new SignaturePad(signatureCanvas, {
            minWidth: 1,
            maxWidth: 2,
        });

        const underLayerContext = underLayerCanvas.getContext("2d");

        if (underLayerContext) {
            drawText(underLayerContext);
        }

        if (signaturePadRef.current) {
            signaturePadRef.current.addEventListener("endStroke", () => {
                setIsSignaturePadEmpty(
                    signaturePadRef.current?.isEmpty() ?? true
                );
            });
        }
    }, [i18n.i18n.language]); // eslint-disable-line react-hooks/exhaustive-deps

    const drawText = (underLayerContext: CanvasRenderingContext2D) => {
        underLayerContext.font =
            "italic 20px 'Helvetica Neue', Arial, sans-serif";
        underLayerContext.fillStyle = "lightgray";

        // Defines horizontal alignment (center)
        underLayerContext.textAlign = "center";
        // Defines vertical alignment (middle)
        underLayerContext.textBaseline = "middle";
        // Defines x and y coords so as to draw texts at center of canvas
        const x = underLayerContext.canvas.width / 2;
        const y = underLayerContext.canvas.height / 2;

        underLayerContext.fillText(i18n.t("signature.signHere"), x, y);
        underLayerContext.fillText(user?.displayName as string, x, y + 30);
    };

    const handleSignature = async (signature: string | null) => {
        try {
            const sendSignature = await performSignature({
                signature: signature as string,
            });

            if (sendSignature.ok) {
                const refreshedUser = await sendSignature.json();
                setUser(refreshedUser);
            } else {
                handleLoginError(sendSignature.status);
            }
        } catch (error) {
            setError(error as NotFoundError | UnknownError);
        }
    };

    const handleClear = () => {
        if (isSignaturePadEmpty) {
            return;
        }

        signaturePadRef.current?.clear();
        setIsSignaturePadEmpty(true);

        const underLayerCanvas =
            underLayerSignatureCanvasRef.current as HTMLCanvasElement;
        const underLayerContext = underLayerCanvas.getContext("2d");

        if (underLayerContext) {
            drawText(underLayerContext);
        }
    };

    const handleValidate = async () => {
        if (!isSignaturePadEmpty) {
            setIsSignaturePadEmpty(false);
            const data = signaturePadRef.current?.toDataURL() ?? null;
            await handleSignature(data);
        }
    };

    if (error) {
        return <ErrorPage error={error} />;
    }

    return (
        <>
            <LogoutWrapper>
                <SignatureLogoutButton />
            </LogoutWrapper>
            <Layout>
                <>
                    <Wrapper>
                        <StyledCanvasWrapper>
                            <StyledCanvas
                                ref={canvasSignatureRef}
                                style={{ zIndex: "1" }}
                            />
                            <StyledCanvas
                                ref={underLayerSignatureCanvasRef}
                                style={{ zIndex: "0" }}
                            />
                        </StyledCanvasWrapper>
                        <ButtonWrapper>
                            <StyledOutlineButton
                                onClick={handleClear}
                            >{`${i18n.t(
                                "signature.clear"
                            )}`}</StyledOutlineButton>
                            <StyledButton
                                onClick={handleValidate}
                                disabled={isSignaturePadEmpty}
                            >
                                {`${i18n.t("validate")}`}
                            </StyledButton>
                        </ButtonWrapper>
                    </Wrapper>
                    {user && user?.proxies.length > 0 ? (
                        <ProxiesAccordion>
                            <StyledWrapperProxies>
                                {user?.proxies.map((proxy: any) => {
                                    return (
                                        <div key={proxy.numTelecoEncrypted}>
                                            <p>{proxy.displayName}</p>
                                        </div>
                                    );
                                })}
                            </StyledWrapperProxies>
                        </ProxiesAccordion>
                    ) : null}
                </>
            </Layout>
        </>
    );
};

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    > p {
        margin: auto;
        padding: 2rem 0 1rem 0;
        font-size: 0.75rem;
        line-height: 22px;
        text-align: left;
        width: 620px;
        > a {
            text-decoration: underline;
            color: black;
        }
        @media (max-width: 768px) {
            width: 320px;
        }
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    width: 400px;
    justify-content: end;
`;

const StyledWrapperProxies = styled.div`
    padding-left: 1.5rem;
`;

const StyledCanvasWrapper = styled.div`
    position: relative;
    width: 470px;
    height: 300px;
`;

const StyledCanvas = styled.canvas`
    border: 1px solid gray;
    cursor: crosshair;
    position: absolute;
`;

const StyledOutlineButton = styled.button.attrs({ type: "button" })`
    padding: 6px 12px;
    margin: 0.5rem 0.5rem;
    font-size: 14px;
    cursor: pointer;
    background-color: #fafafa;
    border: 1px solid var(${CSS_VARIABLES.PRIMARY_BUTTON_BACKGROUND_COLOR});
    color: var(${CSS_VARIABLES.PRIMARY_BUTTON_BACKGROUND_COLOR});
    :hover {
        cursor: pointer;
        filter: brightness(0.8);
    }
    :focus-visible {
        outline: solid 2px var(${CSS_VARIABLES.PRIMARY_BUTTON_BACKGROUND_COLOR});
    }
    :disabled {
        cursor: not-allowed;
        filter: brightness(0.8);
    }
`;

const StyledButton = styled(StyledOutlineButton)`
    background-color: var(${CSS_VARIABLES.PRIMARY_BUTTON_BACKGROUND_COLOR});
    border: 1px solid var(${CSS_VARIABLES.PRIMARY_BUTTON_BACKGROUND_COLOR});
    color: #fff;
    margin-right: 0;
    :focus-visible {
        outline: solid 2px var(${CSS_VARIABLES.PRIMARY_BUTTON_BACKGROUND_COLOR});
    }
`;

export default Signature;
