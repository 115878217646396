import React, { useEffect, useState } from "react";
import styled, { ThemeProvider } from "styled-components";

import { QuestionType } from "types/questionTypes";
import { white } from "./theme";
import { VoteStatus } from "types/voteEnum";

import ScreenRemoteVotebox from "./ScreenRemote/ScreenRemoteVotebox";
import KeyboardVotebox from "./Keyboard/KeyboardVotebox";

const ANSWER_MAX_LENGTH = 3;

type Props = {
    onValidate: any;
    remoteMaxNumberButton: number;
    availableAnswers: Array<string>;
    numberOfSeats: number;
};

const VirtualRemoteVoteboxElectionPlurinominal = ({
    onValidate,
    remoteMaxNumberButton,
    availableAnswers,
    numberOfSeats,
}: Props) => {
    const [answers, setAnswers] = useState<Array<number | undefined>>([]);

    const [voteStatus, setVoteStatus] = useState<string>(VoteStatus.Idle);
    const [currentValue, setCurrentValue] = useState<string>("");
    const [indexOfSeatToBeFilled, setIndexOfSeatToBeFilled] =
        useState<number>(1);

    const hasResponseBeenValidated =
        answers[indexOfSeatToBeFilled - 1] !== undefined ? true : false;

    const newAnswers = [...answers];

    const isSuccessVoteStatus = voteStatus === VoteStatus.Success;

    // Change the current value if we change the index or if the answers change
    useEffect(() => {
        setCurrentValue(answers[indexOfSeatToBeFilled - 1]?.toString() ?? "");
    }, [indexOfSeatToBeFilled, answers]);

    // Send votes whenever a answer is updated
    useEffect(() => {
        if (
            777 === answers[0] ||
            999 === answers[0] ||
            (answers.length === numberOfSeats && hasResponseBeenValidated)
        ) {
            setCurrentValue("OK");
            setVoteStatus(VoteStatus.Success);
        }

        if (0 !== answers.length) {
            onValidate(answers.toString())
                .then(() => {
                    setVoteStatus(VoteStatus.Success);
                })
                .catch(() => {
                    setVoteStatus(VoteStatus.Error);
                });
        }
    }, [answers, hasResponseBeenValidated, numberOfSeats, onValidate]);

    const handleValidate = () => {
        if (isSuccessVoteStatus) {
            return;
        }

        // You can vote only for someone who has an existing id
        if (!availableAnswers?.includes(currentValue)) {
            setCurrentValue("ERR");
            return;
        }

        //You can't vote two times for same person
        if (answers.includes(Number(currentValue))) {
            setCurrentValue("ERR");
            return;
        }

        if (
            indexOfSeatToBeFilled !== 1 &&
            ("777" === currentValue || "999" === currentValue)
        ) {
            setCurrentValue("ERR");
            return;
        }

        //It's possible to vote white or abstention only in first index
        if ("777" === currentValue || "999" === currentValue) {
            setAnswers([Number(currentValue)]);
            return;
        }

        // You save answer for each index
        newAnswers[indexOfSeatToBeFilled - 1] = Number(currentValue);
        setAnswers(newAnswers);

        // Up the index only if is not the last index
        if (indexOfSeatToBeFilled !== numberOfSeats) {
            setIndexOfSeatToBeFilled(indexOfSeatToBeFilled + 1);
        }
    };

    const onReset = () => {
        if (isSuccessVoteStatus) {
            if (777 === answers[0] || 999 === answers[0]) {
                setAnswers([]);
                setIndexOfSeatToBeFilled(1);
            } else {
                newAnswers[indexOfSeatToBeFilled - 1] = undefined;
                setAnswers(newAnswers);
            }
            setVoteStatus(VoteStatus.Idle);
            return;
        }

        // Delete the current answer if it exists
        const currentAnswer = newAnswers[indexOfSeatToBeFilled - 1];

        if (currentAnswer === undefined) {
            setCurrentValue("");
        } else {
            newAnswers[indexOfSeatToBeFilled - 1] = undefined;
            setAnswers(newAnswers);
        }
    };

    const onArrowUpClick = () => {
        if (
            (isSuccessVoteStatus && 777 === answers[0]) ||
            (isSuccessVoteStatus && 999 === answers[0])
        ) {
            return;
        }

        if (1 === indexOfSeatToBeFilled) {
            setIndexOfSeatToBeFilled(numberOfSeats);
        } else {
            setIndexOfSeatToBeFilled(indexOfSeatToBeFilled - 1);
        }
    };

    const onArrowDownClick = () => {
        if (
            (isSuccessVoteStatus && 777 === answers[0]) ||
            (isSuccessVoteStatus && 999 === answers[0])
        ) {
            return;
        }

        if (indexOfSeatToBeFilled === numberOfSeats) {
            setIndexOfSeatToBeFilled(1);
        } else {
            setIndexOfSeatToBeFilled(indexOfSeatToBeFilled + 1);
        }
    };

    const onKeyButtonClick = (value: React.SetStateAction<string>) => {
        if (isSuccessVoteStatus) {
            newAnswers[indexOfSeatToBeFilled - 1] = undefined;

            setAnswers(newAnswers);
            setVoteStatus(VoteStatus.Idle);
            return;
        }

        if ("ERR" === currentValue) {
            setCurrentValue(value);
            return;
        }

        if (currentValue?.length >= ANSWER_MAX_LENGTH) {
            return;
        }

        if (hasResponseBeenValidated) {
            setCurrentValue(value);
            return;
        }

        setCurrentValue(`${currentValue}${value}`);
    };

    return (
        <ThemeProvider theme={white}>
            <Wrapper>
                <ScreenRemoteVotebox
                    answer={currentValue}
                    voteStatus={voteStatus}
                    indexOfSeatToBeFilled={
                        "OK" === currentValue
                            ? undefined
                            : indexOfSeatToBeFilled
                    }
                />
                <KeyboardVotebox
                    handleValidate={handleValidate}
                    onReset={onReset}
                    onButtonClick={onKeyButtonClick}
                    remoteMaxNumberButton={remoteMaxNumberButton}
                    onArrowUpClick={onArrowUpClick}
                    onArrowDownClick={onArrowDownClick}
                    typeVote={QuestionType.ELECTION}
                />
            </Wrapper>
        </ThemeProvider>
    );
};

const Wrapper = styled.div`
    border-radius: 16px;
    padding: 8px 8px 16px;
    width: 225px;
    border: ${(props) => props.theme.border} 1px solid;
    background: ${(props) => props.theme.backgroundColor};
    margin: auto;
`;

export default VirtualRemoteVoteboxElectionPlurinominal;
