import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { DisplayType, ColorRemote, ButtonType } from "types/voteEnum";
import { QuestionType as QuestionVoteType } from "types/dataVoteType";
import { QuestionType } from "types/questionTypes";

import VirtualRemoteQuizzbox from "./VirtualRemoteQuizzbox";
import VirtualRemoteVoteboxDeliberation from "./VirtualRemoteVoteboxDeliberation";
import VirtualRemoteVoteboxElectionUninominal from "./VirtualRemoteVoteboxElectionUninominal";
import VirtualRemoteVoteboxElectionPlurinominal from "./VirtualRemoteVoteboxElectionPlurinominal";

type Props = {
    onValidate: (result: string) => any;
    displayType: DisplayType;
    color: ColorRemote;
    lang: string;
    isSecret: boolean;
    answerCanBeModified: boolean;
    buttonType: ButtonType;
    remoteMaxNumberButton: number;
    minResponseLength?: number;
    maxResponseLength?: number;
    valueCanAppearMultipleTimes: boolean;
    numberOfSeats: number;
    authorizeWeightDistribution: boolean;
};

type VirtualRemoteBridgeProps = {
    onValidate: (result: string) => any;
    color: string;
    currentUser: any;
    vote: QuestionVoteType;
};

export default function VirtualRemoteBridge({
    vote,
    onValidate,
    color,
    currentUser,
}: VirtualRemoteBridgeProps) {
    const createPropsFromVote = (
        voteParam: QuestionVoteType
    ): Omit<Props, "onValidate" | "color"> => {
        const props: Partial<Props> = {};
        const question = voteParam;

        switch (question.type) {
            case QuestionType.NUMERICAL:
            case QuestionType.NUMERICAL_CORRECT_ANSWER:
                props.displayType = DisplayType.NUMERIC;
                break;
            case QuestionType.ROLL_CALL:
            case QuestionType.ANONYMOUS_ROLL_CALL:
            case QuestionType.ID_ROLL_CALL:
                props.displayType = DisplayType.CALL;
                break;
            case QuestionType.DELIBERATION:
                props.authorizeWeightDistribution =
                    question.authorizeWeightDistribution;
                break;
            case QuestionType.ELECTION:
                props.numberOfSeats = question.numberOfSeats;
                break;
            case QuestionType.TAG_CLOUD:
                throw new Error(`Unsupported question type : ${question.type}`);
            case QuestionType.GROUPED:
                // eslint-disable-next-line no-lone-blocks
                {
                    props.displayType = DisplayType.OTHER;

                    if (0 === Number(question.displayMode)) {
                        props.buttonType = ButtonType.LETTER;
                    }

                    props.minResponseLength = question.subTitles.length;
                    props.maxResponseLength = question.subTitles.length;
                    props.valueCanAppearMultipleTimes = true;
                }
                break;
            case QuestionType.TEAMS:
            case QuestionType.CLASSIFICATION:
            case QuestionType.LIST:
            case QuestionType.MULTIPLE_CHOICE_CORRECT_ANSWER:
            case QuestionType.UNIQUE_CHOICE_CORRECT_ANSWER:
            case QuestionType.MULTIPLE_CHOICE:
            case QuestionType.ORDERED_ANSWERS:
            case QuestionType.CORRECT_ORDERED_ANSWERS:
            case QuestionType.TEST:
            case QuestionType.UNDEFINED:
            case QuestionType.UNIQUE_CHOICE:
            default: {
                props.displayType = DisplayType.OTHER;

                if (0 === Number(question.displayMode)) {
                    props.buttonType = ButtonType.LETTER;
                }

                props.minResponseLength = question.minNumberOfAnswers;
                props.maxResponseLength = question.maxNumberOfAnswers;
                props.valueCanAppearMultipleTimes = question.allowDuplicate;
            }
        }

        if (
            QuestionType.LIST === question.type &&
            question.propositions.length > 9
        ) {
            props.maxResponseLength = 2;
            props.valueCanAppearMultipleTimes = true;
        }

        if (!props.buttonType) {
            props.buttonType = ButtonType.NUMBER;
        }

        // Compute the responseMaxNumber
        // If the list of propositions is empty, that means that all numbers are allowed
        // Else we get the max number among the propositions
        if (0 === question.propositions.length) {
            props.remoteMaxNumberButton = 10;
        } else {
            props.remoteMaxNumberButton = Math.max(
                ...question.propositions.map(function (element: { id: any }) {
                    return Number(element.id);
                })
            );
        }

        props.isSecret = question.isSecret;
        props.answerCanBeModified = question.canAnswerBeUpdated;

        return props as Required<typeof props>;
    };

    const i18n = useTranslation();
    const question = vote;

    const virtualRemoteProps = createPropsFromVote(vote);

    const convertLetterToNumberAndValidate = (answer: string) => {
        //Change answer to correct format
        let promise;
        if (ButtonType.LETTER === virtualRemoteProps.buttonType) {
            const LETTERS_MAPPING = "JABCDEFGHI";
            promise = onValidate(
                answer
                    .split("")
                    .map((x) => {
                        const result = LETTERS_MAPPING.indexOf(x);
                        return result;
                    })
                    .join("")
            );
        } else {
            promise = onValidate(answer);
        }
        return promise;
    };

    const getRemoteColor = () => {
        if (
            question.type === QuestionType.DELIBERATION ||
            question.type === QuestionType.ELECTION
        ) {
            return ColorRemote.WHITE;
        } else if ("standard" === color) {
            return ColorRemote.ORANGE;
        } else {
            return ColorRemote.WHITE;
        }
    };

    const renderWeighting = () =>
        currentUser.weight > 1 ? (
            <p>
                {`${i18n.t("numberOfVote")}`} {currentUser.weight}
            </p>
        ) : null;

    const renderVirtualRemote = () => {
        //Deliberation
        if (question.type === QuestionType.DELIBERATION) {
            return (
                <WrapperVotebox>
                    {renderWeighting()}
                    <VirtualRemoteVoteboxDeliberation
                        {...virtualRemoteProps}
                        onValidate={convertLetterToNumberAndValidate}
                    />
                </WrapperVotebox>
            );
        }

        const propositionIds = question.propositions.map(
            (proposition: any) => proposition.id
        );

        // Election uninominal
        if (
            question.type === QuestionType.ELECTION &&
            1 === question.numberOfSeats
        ) {
            return (
                <WrapperVotebox>
                    {renderWeighting()}
                    <VirtualRemoteVoteboxElectionUninominal
                        {...virtualRemoteProps}
                        availableAnswers={propositionIds}
                        onValidate={convertLetterToNumberAndValidate}
                    />
                </WrapperVotebox>
            );
        }

        if (
            question.type === QuestionType.ELECTION &&
            question.numberOfSeats > 1
        ) {
            return (
                <WrapperVotebox>
                    {renderWeighting()}
                    <VirtualRemoteVoteboxElectionPlurinominal
                        {...virtualRemoteProps}
                        availableAnswers={propositionIds}
                        onValidate={convertLetterToNumberAndValidate}
                    />
                </WrapperVotebox>
            );
        }

        return (
            <VirtualRemoteQuizzbox
                {...virtualRemoteProps}
                onValidate={convertLetterToNumberAndValidate}
                color={getRemoteColor()}
            />
        );
    };

    return (
        <div>
            <Wrapper>{renderVirtualRemote()}</Wrapper>
        </div>
    );
}

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
`;

const WrapperVotebox = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
`;
