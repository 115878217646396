import { useState, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { QuestionType } from "types/dataVoteType";
import { VoteStatus } from "types/voteEnum";
import useVoteState, { VoteState, Person } from "./useVoteState";
import { Socket } from "socket.io-client";
import { useHolderVoteMustBeAppliedToProxies } from "../common/AutomaticVoteForProxiesComponents/useHolderVoteMustBeAppliedToProxies";

import VoteHeader from "../common/Header/VoteHeader";
import VoteBasic from "./Vote/VoteBasic";
import VoteDistribution from "./Vote/VoteDistribution";
import ParticipantButton from "./Vote/ParticipantButton";
import ToggleSwitch from "../common/AutomaticVoteForProxiesComponents/ToggleSwitch";
import ProxiesAccordion from "../common/AutomaticVoteForProxiesComponents/ProxiesAccordion";
import emitAsync from "components/helper/emitAsync";

type Props = {
    question: QuestionType;
    user: Person;
    proxies: Array<Person>;
    responseFormatVersion: number;
};

declare global {
    interface Window {
        socketClient: Socket;
        constant: { events: { browsers: { ANSWER: string } } };
        currentVote: { responseFormatVersion: number };
    }
}

const isZoomTemplate = document
    .getElementById("DesignVote")
    ?.classList.contains("streaming");

const Election = ({
    question,
    user,
    proxies,
    responseFormatVersion,
}: Props) => {
    const i18n = useTranslation();
    const [votes, dispatchVoteAction] = useVoteState(question, proxies, user);
    const {
        holderVoteMustBeAppliedToProxies,
        setHolderVoteMustBeAppliedToProxies,
    } = useHolderVoteMustBeAppliedToProxies(
        question.authorizeWeightDistribution
    );
    const [shouldSendAnswer, setShouldSendAnswer] = useState(false);

    const participants = [user, ...proxies];

    useEffect(() => {
        const userAnswer = votes[0].answers.find((answer) => answer.weight > 0);
        const userVoteStatus = votes[0].voteStatus;

        // This useEffect concerns the automatic vote for proxies (toggle switch)
        // If the toggle switch is active and user has already voted
        // This vote is applied to proxies
        if (shouldSendAnswer) {
            if (userAnswer && userVoteStatus === VoteStatus.PendingValidation) {
                participants.forEach((participant) => {
                    handleValidate(participant.numTelecoEncrypted);
                });
            }

            setShouldSendAnswer(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldSendAnswer]);

    const handleValidate = (voterId: string) => {
        const voteToSend = votes.find(
            (vote) => vote.voterId === voterId
        ) as VoteState;

        const answersWithWeight = voteToSend.answers.filter(
            (answer) => answer.weight > 0
        );

        const data = {
            votingDeviceId: voterId,
            responseFormatVersion: responseFormatVersion,
            answers: answersWithWeight.map((answer) => ({
                questionId: question.id,
                answer: answer.id,
                weight: answer.weight,
            })),
        };

        dispatchVoteAction({
            type: "sendAnswersRequest",
            voterId: voterId,
        });

        emitAsync(data)
            .then(() => {
                dispatchVoteAction({
                    type: "sendAnswersSuccess",
                    voterId: voterId,
                });
            })
            .catch(() => {
                dispatchVoteAction({
                    type: "sendAnswersError",
                    voterId: voterId,
                });
            });
    };

    const renderVoteBasic = (voter: Person, state: VoteState) => {
        const voterIsTheHolder =
            voter.numTelecoEncrypted === user.numTelecoEncrypted &&
            holderVoteMustBeAppliedToProxies;

        //We should deactivate the toggle
        //In case of toggle is Active and the voter is one proxy,
        const turnOffToggle = () => {
            if (
                voter.numTelecoEncrypted !== user.numTelecoEncrypted &&
                holderVoteMustBeAppliedToProxies
            ) {
                setHolderVoteMustBeAppliedToProxies(false);
            }
        };

        return (
            <VoteBasic
                propositions={question.propositions}
                answers={state.answers}
                isSecret={question.isSecret}
                voterWeight={voter.weight}
                voteStatus={state.voteStatus}
                maxNumberOfAnswers={question.maxNumberOfAnswers}
                selectProposal={(propositionId: string) => {
                    if (voterIsTheHolder) {
                        dispatchVoteAction({
                            type: "replaceVoteForEveryParticipant",
                            id: propositionId,
                            weight: voter.weight,
                        });
                    } else {
                        dispatchVoteAction({
                            type: "updateWeight",
                            id: propositionId,
                            voterId: voter.numTelecoEncrypted,
                            weight: voter.weight,
                        });
                        turnOffToggle();
                    }
                }}
                unselectProposal={(propositionId: string) => {
                    if (voterIsTheHolder) {
                        dispatchVoteAction({
                            type: "replaceVoteForEveryParticipant",
                            id: propositionId,
                            weight: 0,
                        });
                    } else {
                        dispatchVoteAction({
                            type: "updateWeight",
                            id: propositionId,
                            voterId: voter.numTelecoEncrypted,
                            weight: 0,
                        });
                        turnOffToggle();
                    }
                }}
                validateVote={() => {
                    if (voterIsTheHolder) {
                        participants.forEach((participant) => {
                            handleValidate(participant.numTelecoEncrypted);
                        });
                    } else {
                        handleValidate(voter.numTelecoEncrypted);
                    }
                }}
                resetVote={() => {
                    if (voterIsTheHolder) {
                        dispatchVoteAction({
                            type: "resetEveryOneVotes",
                        });
                    } else {
                        dispatchVoteAction({
                            type: "resetVote",
                            voterId: voter.numTelecoEncrypted,
                        });
                        turnOffToggle();
                    }
                }}
            />
        );
    };

    const renderVoteDistribution = (voter: Person, state: VoteState) => {
        return (
            <VoteDistribution
                propositions={question.propositions}
                answers={state.answers}
                isSecret={question.isSecret}
                voterWeight={voter.weight}
                voteStatus={state.voteStatus}
                updateWeight={(id: string, weight: number) => {
                    dispatchVoteAction({
                        id,
                        weight,
                        type: "updateWeight",
                        voterId: voter.numTelecoEncrypted,
                    });
                }}
                validateVote={() => {
                    handleValidate(voter.numTelecoEncrypted);
                }}
                resetVote={() => {
                    dispatchVoteAction({
                        type: "resetVote",
                        voterId: voter.numTelecoEncrypted,
                    });
                }}
            />
        );
    };

    const isVoteDistribution = (participant: Person) => {
        return question.authorizeWeightDistribution && 1 !== participant.weight;
    };

    const renderParticipantButton = (
        participant: Person,
        participantVote: VoteState
    ) => {
        return (
            <ParticipantButton
                voteStatus={participantVote.voteStatus}
                voterWeight={participant.weight}
                displayName={participant.displayName}
            >
                {isVoteDistribution(participant)
                    ? renderVoteDistribution(participant, participantVote)
                    : renderVoteBasic(participant, participantVote)}
            </ParticipantButton>
        );
    };

    // There are 3 cases:
    // 1. There are no proxies (single voter) and no weight distribution
    // 2. There are no proxies (single voter) but weight distribution is allowed
    // 3. There are proxies (severals voters). Note: in this case there is no weight distribution
    const renderVote = () => {
        const userVote = votes.find(
            (vote) => vote.voterId === user.numTelecoEncrypted
        ) as VoteState;

        //Without proxies
        if (0 === proxies.length) {
            //No vote allowed for the user
            if (0 === user.weight) {
                return (
                    <NoVoteAllowed>{i18n.t("noAllowedToVote")}</NoVoteAllowed>
                );
            } else if (isVoteDistribution(user)) {
                return renderVoteDistribution(user, userVote);
            } else {
                return renderVoteBasic(user, userVote);
            }
        }

        //With proxies
        if (!question.authorizeWeightDistribution) {
            // Automatic proxies's vote section
            return (
                <>
                    <ParticipantButton
                        voteStatus={userVote.voteStatus}
                        voterWeight={user.weight}
                        displayName={user.displayName}
                    >
                        {renderVoteBasic(user, userVote)}
                    </ParticipantButton>
                    {0 !== user.weight ? (
                        <ToggleSwitch
                            checked={holderVoteMustBeAppliedToProxies}
                            onChange={() => {
                                setHolderVoteMustBeAppliedToProxies(
                                    !holderVoteMustBeAppliedToProxies
                                );

                                if (holderVoteMustBeAppliedToProxies) {
                                    return;
                                }
                                // When toggle is activated
                                // We delete the votes of the proxies
                                // And  apply the holder vote to his proxies
                                // If the holder has already voted

                                dispatchVoteAction({
                                    type: "resetProxyVotes",
                                });

                                const userAnswer = votes[0].answers.filter(
                                    (answer) => answer.weight > 0
                                );

                                if (userAnswer) {
                                    userAnswer.forEach((answer) => {
                                        dispatchVoteAction({
                                            type: "replaceVoteForEveryParticipant",
                                            id: answer.id,
                                            weight: answer.weight,
                                        });
                                    });
                                    setShouldSendAnswer(true);
                                }
                            }}
                        />
                    ) : null}
                    <ProxiesAccordion>
                        <div>
                            <StyledProxyText>{i18n.t("proxy")}</StyledProxyText>
                            {proxies.map((proxy) => {
                                const proxyVote = votes.find(
                                    (vote) =>
                                        vote.voterId ===
                                        proxy.numTelecoEncrypted
                                ) as VoteState;
                                return (
                                    <div key={proxy.numTelecoEncrypted}>
                                        <ParticipantButton
                                            voteStatus={proxyVote.voteStatus}
                                            voterWeight={proxy.weight}
                                            displayName={proxy.displayName}
                                        >
                                            {renderVoteBasic(proxy, proxyVote)}
                                        </ParticipantButton>
                                    </div>
                                );
                            })}
                        </div>
                    </ProxiesAccordion>
                </>
            );
        }

        return (
            <>
                <div>{renderParticipantButton(user, userVote)}</div>
                <div>
                    <p>{i18n.t("proxy")}</p>
                    {proxies.map((proxy) => {
                        const proxyVote = votes.find(
                            (vote) => vote.voterId === proxy.numTelecoEncrypted
                        ) as VoteState;
                        return (
                            <div key={proxy.numTelecoEncrypted}>
                                {renderParticipantButton(proxy, proxyVote)}
                            </div>
                        );
                    })}
                </div>
            </>
        );
    };

    return (
        <>
            <Wrapper>
                <WrapperVote>
                    <VoteHeader question={question} />
                    {renderVote()}
                </WrapperVote>
            </Wrapper>
        </>
    );
};

const Wrapper = styled.div`
    width: 100%;
`;

const WrapperVote = styled.div`
    width: ${() => (isZoomTemplate ? "90%" : "100%")};
    @media (max-width: 768px) {
        max-width: 350px;
    }
`;

const NoVoteAllowed = styled.p`
    color: #f62323;
    font-weight: 600;
`;

const StyledProxyText = styled.p`
    font-size: 14px;
    margin: 0.5rem 0;
`;

export default Election;
