import { ZoomMtg } from "@zoom/meetingsdk";
import { SessionType } from "types/sessionType";
import { User } from "types/User";
import { jwtDecode } from "jwt-decode";
import { getSessionInformation } from "components/helper/callApi";
import { SetStateAction } from "react";

type Params = {
    session: SessionType;
    user: User;
    lang: string;
    setSession: (value: SetStateAction<SessionType | null>) => void;
};

export const leaveMeetingHandler = (confirmMsg: string) => {
    if (window.confirm(confirmMsg)) {
        ZoomMtg.leaveMeeting({});
    }
};

export const toggleZoomDisplayIfPresent = (display: "none" | "block") => {
    const zooMtg = document.getElementById("zmmtg-root") as HTMLElement;

    const zoomAriaNotify = document.getElementById(
        "aria-notify-area"
    ) as HTMLElement;

    if (zooMtg) {
        zooMtg.style.display = display;
    }

    if (zoomAriaNotify) {
        zoomAriaNotify.style.display = display;
    }
};

export const loadZoomMeetingClient = ({
    session,
    user,
    lang,
    setSession,
}: Params) => {
    toggleZoomDisplayIfPresent("block");

    setInterval(() => {
        console.info("Starting zoom signature expiration checking ...");
        const { exp } = jwtDecode(session.zoomSignature) as { exp: number };
        const now = Math.floor(Date.now() / 1000);
        //10 minutes before expiration date
        const isZoomSignatureExpiringSoon = exp - now < 600;

        if (isZoomSignatureExpiringSoon) {
            console.info(
                "Zoom signature is expiring soon, requesting a new one"
            );
            getSessionInformation(session.codeOnline, ["zoomSignature"])
                .then((response) => {
                    if (response.ok) {
                        response.json().then((data) => {
                            setSession(data as SessionType);
                        });
                    } else {
                        console.error(
                            "Error while trying to refresh zoom signature"
                        );
                    }
                })
                .catch((err) => {
                    console.error(
                        "Error while trying to refresh zoom signature"
                    );
                });
        }
    }, 10 * 60 * 1000); //Every 10 minutes

    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareWebSDK();

    const sdkKey = process.env.REACT_APP_ZOOM_SDK_KEY as string;
    const meetingNumber = session.streamingLogin;
    const password = session.streamingPassword;
    const userName = user.displayName;
    const userEmail = user.email || ("" as string);
    const leaveUrl = process.env.REACT_APP_URL;
    const signature = session.zoomSignature;

    ZoomMtg.i18n.load(lang as any);
    ZoomMtg.init({
        leaveUrl: leaveUrl as string,
        patchJsMedia: true,
        success: (success: any) => {
            ZoomMtg.join({
                signature: signature,
                sdkKey: sdkKey,
                meetingNumber: meetingNumber,
                passWord: password,
                userName: userName,
                userEmail: userEmail,
                success: (success: any) => {
                    console.log(success);
                },
                error: (error: any) => {
                    console.error(error);
                },
            });
        },
        error: (error: any) => {
            console.error(error);
        },
    });
};
